
import MainLayout from "../../layouts/MainLayout";
const Landing = () => import("@/views/landing/Landing");

let defaultRoute = [
    {
        path: '/',
        component: MainLayout,
        name: 'Home',
        children: [
            {
                path: '/',
                component: Landing,
                name: 'Landing', 
            },
            {
                path: ':lang',
                component: Landing,
                name: 'Lang_Landing', 
            }
        ]            
    },   
    
]
export default []
    .concat(defaultRoute)