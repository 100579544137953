<template>
  <b-navbar type="dark" variant="secondary" class="py-3">
    <div class="container footer">
         
        <!-- языки -->
        <b-navbar-nav>
          <b-nav-item-dropdown dropup no-caret>
            <template #button-content>
              <span class="p-2 border">{{lang}}</span>
            </template>
            <b-dropdown-item :to="{name:'Lang_Landing', params: { lang: 'en' } }">EN</b-dropdown-item>
            <b-dropdown-item :to="{name:'Lang_Landing', params: { lang: 'es' } }">ES</b-dropdown-item>
            <b-dropdown-item :to="{name:'Lang_Landing', params: { lang: 'de' } }">DE</b-dropdown-item>
            <b-dropdown-item :to="{name:'Lang_Landing', params: { lang: 'ru' } }">RU</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-nav-text class="ml-auto">
          <span>©</span>
          <span class="ml-1">{{ $t("brand.Name") }}</span>
          <span class="ml-1">{{year}}</span>           
        </b-nav-text> 

        <b-navbar-brand href="#" class="ml-3">
          <img
            src="/favicon-144x144_light.png"
            class="d-inline-block align-top logoImg"
            alt="Logo"
          />
        </b-navbar-brand> 
    </div>
  </b-navbar>
</template>

<script>

import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Footer",
  components: {},
  computed: {
    ...mapGetters('application', ['config', 'lang']),
    year(){
      return moment().format('YYYY')
    },    
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer{
  font-size: small;
}
.logoImg{
  height: 32px;
  width: 32px;
}
</style>
